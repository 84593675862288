import * as React from 'react';

import {
    GoogleMap, Marker, useJsApiLoader
} from '@react-google-maps/api';

import * as styles from './gmap.module.css';

interface GMapProps {
    center: google.maps.LatLngLiteral;
}

export const GMap: React.FC<GMapProps> = ({ center }) => {
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_GMAPS_API_KEY || ''
    });

    return (
        <>
            {isLoaded && (
                <GoogleMap
                    mapContainerClassName={styles.gmap}
                    center={center}
                    zoom={17}
                    clickableIcons={false}
                    options={{
                        controlSize: 24,
                        fullscreenControl: false,
                        mapTypeControl: false,
                        panControl: false,

                    }}
                >
                    <Marker position={center} />
                </GoogleMap>
            )}
        </>
    );
};
