module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"atawiz.fr","short_name":"atawiz.fr","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/assets/favicon/favicon-64.png","icons":[{"src":"src/assets/favicon/favicon-192.png","sizes":"192x192","type":"image/png"},{"src":"src/assets/favicon/favicon-160.png","sizes":"160x160","type":"image/png"},{"src":"src/assets/favicon/favicon-96.png","sizes":"96x96","type":"image/png"},{"src":"src/assets/favicon/favicon-64.png","sizes":"64x64","type":"image/png"},{"src":"src/assets/favicon/favicon-32.png","sizes":"32x32","type":"image/png"},{"src":"src/assets/favicon/favicon-16.png","sizes":"16x16","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"4d0d2ffc2f994aa9bf2cd63e2a7f0490"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleTagManager":{"trackingId":"GTM-5JMCXRH","cookieName":"atw-site-gtm","routeChangeEvent":"atawiz-site-route-change"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-5JMCXRH","routeChangeEventName":"atawiz-site-route-change","includeInDevelopment":true,"defaultDataLayer":null,"enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
