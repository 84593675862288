import * as React from 'react';

import { isUndOrEmpty } from '../../../utils/string-utils';
import { CtaButton } from '../../shared/cta-button/cta-button.component';
import * as styles from './contact-form.module.css';
import {
    Contact,
    ContactFormErrors,
    ContactFormProps,
    ContactFormTouched
} from './contact-form.types';

const requiredErrorMsg = 'Ce champ est obligatoire';
const emailErrorMsg = 'Veuillez saisir un e-mail valide';

export const ContactForm: React.FC<ContactFormProps> = ({
    emailSent, emailError, onSubmit
}: ContactFormProps) => {
    const [disabled, setDisabled] = React.useState(true);
    const [valid, setValid] = React.useState(false);
    const [contact, setContact] = React.useState<Contact>({
        firstName: '',
        lastName: '',
        email: '',
        message: '',
        dataProcessingConsent: false
    });
    const [touched, setTouched] = React.useState<ContactFormTouched>({
        firstName: false,
        lastName: false,
        email: false,
        message: false,
        dataProcessingConsent: false
    });
    const [errors, setErrors] = React.useState<ContactFormErrors>({
        firstName: '',
        lastName: '',
        email: '',
        message: '',
        dataProcessingConsent: ''
    });

    React.useEffect(() => {
        setDisabled((!valid) || emailSent || emailError);
    }, [valid, emailSent, emailError]);

    React.useEffect(() => {
        const {
            firstName, lastName, email, message, dataProcessingConsent
        } = contact;
        const errorsLocal: ContactFormErrors = { ...errors };

        errorsLocal.firstName = isUndOrEmpty(firstName) ? requiredErrorMsg : '';

        errorsLocal.lastName = isUndOrEmpty(lastName) ? requiredErrorMsg : '';

        //eslint-disable-next-line max-len
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g;
        if (isUndOrEmpty(email)) {
            errorsLocal.email = requiredErrorMsg;
        } else if (pattern.test(email) === false) {
            errorsLocal.email = emailErrorMsg;
        } else {
            errorsLocal.email = '';
        }

        errorsLocal.message = isUndOrEmpty(message) ? requiredErrorMsg : '';

        errorsLocal.dataProcessingConsent = !dataProcessingConsent ? requiredErrorMsg : '';

        const formValid = (Object.values(errorsLocal) as string[])
            .reduce<boolean>((prev, next) => (prev && isUndOrEmpty(next)), true);

        setValid(formValid);
        setErrors(errorsLocal);
    }, [contact]);

    const handleSubmit = () => {
        if (valid) {
            onSubmit(contact);
        }
    };

    return (
        <React.Fragment>
            <form onSubmit={(e) => e.preventDefault()}>
                <div className={styles.formContainer}>
                    <div className={styles.formRow}>
                        <div className={styles.formCol}>
                            <input
                                type="text"
                                className={styles.fieldInput}
                                name='lastName'
                                placeholder='Nom'
                                onChange={(event) => {
                                    event.preventDefault();
                                    if (event && event.target) {
                                        setContact({
                                            ...contact, lastName: event.target.value
                                        });
                                    }
                                }}
                                onBlur={() => setTouched({
                                    ...touched, lastName: true
                                })}
                                required
                            />
                            <div className={styles.errorRow}>
                                {touched.lastName && isUndOrEmpty(errors.lastName) &&
                                    <span className={styles.formError}>{errors.lastName}</span>}
                            </div>
                        </div>
                        <div className={styles.formCol}>
                            <input
                                type="text"
                                className={styles.fieldInput}
                                name='firstName'
                                placeholder='Prénom'
                                onChange={(event) => {
                                    event.preventDefault();
                                    if (event && event.target) {
                                        setContact({
                                            ...contact, firstName: event.target.value
                                        });
                                    }
                                }}
                                onBlur={() => setTouched({
                                    ...touched, firstName: true
                                })}
                                required
                            />
                            <div className={styles.errorRow}>
                                {touched.firstName && !isUndOrEmpty(errors.firstName) &&
                                    <span className={styles.formError}>{errors.firstName}</span>}
                            </div>
                        </div>
                    </div>
                    <div className={styles.formRow}>
                        <div className={styles.formCol}>
                            <input
                                type='email'
                                className={styles.fieldInput}
                                name='email'
                                placeholder='Adresse e-mail'
                                onChange={(event) => {
                                    event.preventDefault();
                                    if (event && event.target) {
                                        setContact({
                                            ...contact, email: event.target.value
                                        });
                                    }
                                }}
                                onBlur={() => setTouched({
                                    ...touched, email: true
                                })}
                                required
                            />
                            <div className={styles.errorRow}>
                                {touched.email && !isUndOrEmpty(errors.email) &&
                                    <span className={styles.formError}>{errors.email}</span>}
                            </div>
                        </div>
                    </div>
                    <div className={`d-flex flex-column ${styles.formCol}`}>
                        <textarea
                            className={styles.fieldTextarea}
                            name='message'
                            placeholder='Votre message'
                            rows={5}
                            onChange={(event) => {
                                event.preventDefault();
                                if (event && event.target) {
                                    setContact({
                                        ...contact, message: event.target.value
                                    });
                                }
                            }}
                            onBlur={() => setTouched({
                                ...touched, message: true
                            })}
                            required
                        />
                        <div className={styles.errorRow}>
                            {touched.message && !isUndOrEmpty(errors.message) &&
                                <span className={styles.formError}>{errors.message}</span>}
                        </div>
                    </div>
                    <div className={`mt-1 ${styles.formCol}`}>
                        <div className='d-flex'>
                            <input
                                name='dataProcessingConsent'
                                type='checkbox'
                                checked={contact.dataProcessingConsent}
                                onChange={(event) => {
                                    if (event && event.target) {
                                        setContact({
                                            ...contact,
                                            dataProcessingConsent: event.target.checked
                                        });
                                    }
                                }}
                                onBlur={() => setTouched({
                                    ...touched, dataProcessingConsent: true
                                })}
                                required
                            />
                            <label
                                className='mr-2'
                                htmlFor='dataProcessingConsent'
                            >J&#39;accepte que mes données soit collectées</label>
                        </div>
                        <div className={styles.errorRow}>
                            {touched.dataProcessingConsent && !isUndOrEmpty(errors.dataProcessingConsent) &&
                                <span className={styles.formError} >{errors.dataProcessingConsent}</span>}
                        </div>
                    </div>

                    <div className='mt-2 d-flex flex-column align-items-start'>
                        <CtaButton disabled={disabled} onClick={() => handleSubmit()}>
                            {!emailSent ? 'Envoyer' : 'Envoyé'}
                        </CtaButton>
                        {emailError && (
                            <span className={`mt-2 ${styles.formError}`}>
                                Une erreur est survenue.
                            </span>
                        )}
                    </div>
                </div>
            </form>
        </React.Fragment>
    );
};