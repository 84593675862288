import * as React from 'react';

import {
    Link, StaticQuery, graphql
} from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import { isUndOrEmpty } from '../../utils';
import { TitleBlock } from '../shared/title-block/title-block.component';
import { ContactForm } from './contact-form/contact-form.component';
import { Contact } from './contact-form/contact-form.types';
import * as styles from './footer.module.css';
import { GMap } from './gmap/gmap.component';

interface FooterProps {
    strapiSiteFooter: {
        address: string;
        phoneNumber: string;
        email: string;
        latitude: number;
        longitude: number;
    }
}

export const Footer: React.FC = () => {
    const [emailSent, setEmailSent] = React.useState(false);
    const [emailError, setEmailError] = React.useState(false);

    const funcUrl = process.env.REACT_APP_API_URL || '';
    const funcKey = process.env.REACT_APP_API_KEY || '';
    const hostUrl = process.env.REACT_APP_HOST_URL || '';

    const sendEmail = (form: Contact) => {
        if (!isUndOrEmpty(funcUrl) && !isUndOrEmpty(funcKey) && !isUndOrEmpty(hostUrl)) {
            fetch('api/sendEmail', {
                method: 'POST',
                body: JSON.stringify(form as Omit<Contact, 'dataProcessingConsent'>)
            })
                .then(
                    (res) => {
                        setEmailSent(res.ok);
                        setEmailError(!res.ok);
                    },
                    () => setEmailError(true)
                )
                .catch(() => setEmailError(true));
        }
    };

    return (
        <StaticQuery<FooterProps>
            query={graphql`
                query FooterQuery {
                    strapiSiteFooter {
                        address
                        phoneNumber
                        email
                        latitude
                        longitude
                    }
                }
            `}
            render={data => {
                const {
                    address, phoneNumber, email, latitude, longitude
                } = data.strapiSiteFooter;

                return (
                    <div id='contact-form' className={styles.footer}>
                        <TitleBlock className={styles.titleBlock}>RENTRONS EN CONTACT</TitleBlock>
                        <div className={styles.contactContainer}>
                            <div className={styles.formContainer}>
                                <ContactForm emailSent={emailSent} emailError={emailError} onSubmit={sendEmail} />
                            </div>
                            <div className={styles.contactInfoContainer}>
                                <div className={styles.mapContainer}>
                                    <GMap center={{
                                        lat: latitude, lng: longitude
                                    }} />
                                </div>
                                <div className={styles.addressContainer}>
                                    <div className={styles.locationContainer}>
                                        <div className={styles.footerLogoContainer}>
                                            <StaticImage
                                                className={styles.logo}
                                                src='../../assets/images/logo_title_white.png'
                                                alt=''
                                                objectFit='contain'
                                            />
                                        </div>
                                        <p>
                                            {!isUndOrEmpty(address) && <span>{address}</span>}
                                            {!isUndOrEmpty(phoneNumber) && (
                                                <>
                                                    <br />
                                                    <br />
                                                    <span>
                                                        <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
                                                    </span>
                                                </>
                                            )}
                                            {!isUndOrEmpty(email) && (
                                                <>
                                                    <br />
                                                    <br />
                                                    <span>
                                                        <a href={`mailto:${email}`}>{email}</a>
                                                    </span>
                                                </>
                                            )}
                                        </p>
                                    </div>
                                    <div className={styles.socialMediaContainer}>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.lmContainer}>
                            <Link to='/mentions-legales'><span>Mentions légales</span></Link>
                            <div className={styles.dotSeparator} />
                            <Link to='/politique-de-confidentialite'>
                                <span>Politique de confidentialité</span>
                            </Link>
                        </div>
                    </div>
                );
            }}
        />
    );
};