import * as React from 'react';

import * as styles from './title-block.module.css';

interface TitleBlockProps {
    rootClassName?: string;
    className?: string;
    withGreenCircle?: boolean;
    borderColor?: string;
    justifyContent?: string;
}

export const TitleBlock: React.FC<React.PropsWithChildren<TitleBlockProps>> = ({
    children, rootClassName, className, withGreenCircle, borderColor, justifyContent
}) => {
    return (
        <div className={`
            d-flex 
            ${borderColor ? 'flex-column align-items-start' : 'flex-row align-items-end'} 
            ${justifyContent ? justifyContent : 'justify-content-start'}
            ${rootClassName ? rootClassName : ''} 
            ${withGreenCircle ? styles.titleBlockRoot : ''}`
        }>
            <h2 className={className}>
                {children}
            </h2>            
            {
                borderColor && <>
                    <div className='d-none d-md-block' style={{
                        paddingTop: '10px',
                        width: '33%',
                        borderBottom: `5px solid ${borderColor}`
                    }}>
                    </div>

                    <div className='d-block d-md-none' style={{
                        paddingTop: '10px',
                        width: '100%',
                        borderBottom: `5px solid ${borderColor}`
                    }}>
                    </div>
                </>
            }

            {withGreenCircle && <div className={styles.greenCircle} />}
        </div>
    );
};