import * as React from 'react';

import { useLocation } from '@reach/router';
import { motion } from 'framer-motion';
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies';
import { StaticImage } from 'gatsby-plugin-image';
import { useCookies } from 'react-cookie';
import Toggle from 'react-toggle';

import { isBrowser } from '../../utils';
import * as styles from './cookie-consent.module.css';

const cookieList: string[] = [
    'atw-site-gtm'
];

export const CookieConsent: React.FC = () => {
    const [firstTime, setFirstTime] = React.useState<boolean>(true);
    const [choiceMode, setChoiceMode] = React.useState<boolean>(false);
    const location = useLocation();
    if (isBrowser()) {
        initializeAndTrack(location);
    }

    const [cookies, setCookie] = useCookies(cookieList);

    const onChange = (name: string, val: boolean) => {
        setCookie(name, val ? 'true' : 'false');
    };

    const confirm = () => {
        localStorage.setItem('atw-site-consent', 'true');
        setFirstTime(false);
    };

    const confirmAll = () => {
        cookieList.forEach(c => {
            setCookie(c, 'true');
        });
        confirm();
    };

    const refuseAll = () => {
        cookieList.forEach(c => {
            setCookie(c, 'false');
        });
        confirm();
    };

    const variants = {
        otherBtn: {
            btnInitial: {
                backgroundColor: '#fff',
                color: '#000'
            },
            btnHover: {
                backgroundColor: '#dcd6d6',
                color: '#000',
            }
        },
        confirmBtn: {
            btnInitial: {
                backgroundColor: '#4B8DEC',
                color: '#fff',
                fontWeight: 'bold'
            },
            btnHover: {
                backgroundColor: '#1b57ad',
                color: '#fff',
                fontWeight: 'bold'
            }
        }
    };

    React.useEffect(() => {
        const lsFirstTime = localStorage.getItem('atw-site-consent') === null;
        setFirstTime(lsFirstTime);
    }, []);

    if (!firstTime) return null;

    return (
        <motion.div
            className={`position-fixed d-flex flex-column align-items-center 
                        ${styles.root}`
            }
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 1 }}
        >
            <div className={styles.title}>
                <div className={styles.firstRow}>
                    <span className={styles.bigTxt}>Bonjour,</span>
                    <div className={styles.logoContainer}>
                        <StaticImage
                            className={`d-flex align-self-end ${styles.logo}`}
                            src='../../assets/images/logo_title.png'
                            alt='logo atawiz'
                            objectFit='contain'
                        />
                    </div>
                </div>
                <div className={styles.secondRow}>
                    <span>
                        Nous utilisons les outils suivants pour mesurer notre audience,
                        entretenir la relation avec vous et améliorer notre produit !
                    </span>
                </div>
            </div>

            <div className={styles.toggleContainer}>
                {
                    choiceMode && <div className={styles.toggleItem}>
                        <div className={styles.label}>Google Tag Manager</div>
                        <div>
                            <Toggle
                                icons={false}
                                checked={cookies['atw-site-gtm'] === 'true'}
                                onChange={e => onChange('atw-site-gtm', e.target.checked)}
                            />
                        </div>
                    </div>
                }

            </div>
            <div className={styles.btnContainer}>
                {
                    choiceMode ? <>
                        <motion.button
                            className={styles.btn}
                            type='button'
                            onClick={() => { setChoiceMode(false); }}
                            variants={variants['otherBtn']}
                            initial='btnInitial'
                            whileHover='btnHover'
                        >Retour</motion.button>

                        <motion.button
                            className={styles.btn}
                            type='button'
                            onClick={confirm}
                            variants={variants['otherBtn']}
                            initial='btnInitial'
                            whileHover='btnHover'
                        >Ok pour tout</motion.button>

                        <motion.button
                            className={styles.btn}
                            type='button'
                            onClick={confirm}
                            variants={variants['confirmBtn']}
                            initial='btnInitial'
                            whileHover='btnHover'
                        >Je confirme</motion.button>
                    </> : <>
                        <motion.button
                            className={styles.btn}
                            type='button'
                            onClick={refuseAll}
                            variants={variants['otherBtn']}
                            initial='btnInitial'
                            whileHover='btnHover'
                        >Tout refuser</motion.button>

                        <motion.button
                            className={styles.btn}
                            type='button'
                            onClick={() => { setChoiceMode(true); }}
                            variants={variants['otherBtn']}
                            initial='btnInitial'
                            whileHover='btnHover'
                        >Je choisis</motion.button>

                        <motion.button
                            className={styles.btn}
                            type='button'
                            onClick={confirmAll}
                            variants={variants['confirmBtn']}
                            initial='btnInitial'
                            whileHover='btnHover'
                        >Tout accepter</motion.button>
                    </>
                }
            </div>
        </motion.div>
    );
};