import * as React from 'react';

import { motion } from 'framer-motion';
import { Link } from 'gatsby';

import {
    isBrowser,
    isValidUrl
} from '../../../utils/url-utils';
import * as styles from './cta-button.module.css';
import { CtaButtonProps } from './cta-button.types';

export const CtaButton: React.FC<CtaButtonProps> = ({
    children, link, text, className, color, ...rest
}) => {

    const isExternalLink = link && link !== '' && isValidUrl(link);
    const isInternalLink = link && link !== '' && link.indexOf('/') === 0;

    const onClick = () => {
        if (isExternalLink && isBrowser()) {
            window.open(link, '_blank') || window.location.replace(link);
        }
    };

    if (isInternalLink) {
        return <Link to={link}>
            <motion.button
                className={`${className || ''} ${styles.btn}`}
                style={{ backgroundColor: color || '#E3019D' }}
                whileHover={{
                    scale: 1.05, backgroundColor: color || '#F002A6'
                }}
            >
                {children || text || ''}
            </motion.button></Link>;
    }

    return (
        <motion.button
            className={`${className || ''} ${styles.btn}`}
            {...rest}
            onClick={rest.onClick || onClick}
            style={{ backgroundColor: color || '#E3019D' }}
            whileHover={{
                scale: 1.05, backgroundColor: color || '#F002A6'
            }}
        >
            {children || text || ''}
        </motion.button>
    );
};