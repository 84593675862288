// extracted by mini-css-extract-plugin
export var bigTxt = "cookie-consent-module--bigTxt--c01df";
export var btn = "cookie-consent-module--btn--fab1b";
export var btnContainer = "cookie-consent-module--btnContainer--dbf45";
export var firstRow = "cookie-consent-module--firstRow--dc166";
export var hidden = "cookie-consent-module--hidden--acd2f";
export var label = "cookie-consent-module--label--2be52";
export var logo = "cookie-consent-module--logo--aa55d";
export var logoContainer = "cookie-consent-module--logoContainer--d5c8a";
export var root = "cookie-consent-module--root--3d1bf";
export var secondRow = "cookie-consent-module--secondRow--4ac66";
export var title = "cookie-consent-module--title--38f8a";
export var toggleContainer = "cookie-consent-module--toggleContainer--f6961";
export var toggleItem = "cookie-consent-module--toggleItem--57366";