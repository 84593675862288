import * as React from 'react';

import {
    motion, useAnimation, useViewportScroll
} from 'framer-motion';
import { StaticImage } from 'gatsby-plugin-image';

import { isBrowser } from '../../utils/url-utils';
import * as styles from './back-to-top.module.css';

export const BackToTop: React.FC = () => {
    const controls = useAnimation();
    const { scrollY } = useViewportScroll();

    React.useEffect(() => {
        if (isBrowser()) {
            scrollY.onChange((val: number) => {
                if (val > 500) {
                    controls.start('visible');
                } else {
                    controls.start('hidden');
                }
            });
        }

        return () => scrollY.clearListeners();
    }, [scrollY]);

    const scrollToTop = (): void => {
        if (isBrowser()) {
            window.scroll({
                top: 0,
                behavior: 'smooth',
            });
        }
    };

    const variants = {
        visible: { bottom: '20px' },
        hidden: { bottom: '-50px' },
    };

    return (
        <motion.div
            className={styles.backToTop}
            initial='hidden'
            animate={controls}
            variants={variants}
            whileHover={{
                scale: 1.1,
                cursor: 'pointer'
            }}
            onClick={scrollToTop}
            transition={{
                ease: 'easeOut',
                duration: 0.6
            }}
        >
            <StaticImage
                className={styles.bttImage}
                src='../../assets/images/chevron_white.png'
                alt="back to top button" />
        </motion.div>
    );
};
