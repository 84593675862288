// extracted by mini-css-extract-plugin
export var addressContainer = "footer-module--addressContainer--b8c44";
export var contactContainer = "footer-module--contactContainer--88ad0";
export var contactInfoContainer = "footer-module--contactInfoContainer--857fd";
export var dotSeparator = "footer-module--dotSeparator--fafb3";
export var footer = "footer-module--footer--03d8a";
export var footerLogoContainer = "footer-module--footerLogoContainer--80082";
export var formContainer = "footer-module--formContainer--21cfa";
export var lmContainer = "footer-module--lmContainer--1009a";
export var locationContainer = "footer-module--locationContainer--39cbf";
export var logo = "footer-module--logo--458b9";
export var map = "footer-module--map--8cd41";
export var mapContainer = "footer-module--mapContainer--9155d";
export var socialMediaContainer = "footer-module--socialMediaContainer--62a88";
export var socialMediaImg = "footer-module--socialMediaImg--3d822";
export var socialMediaImgContainer = "footer-module--socialMediaImgContainer--a0d31";
export var titleBlock = "footer-module--titleBlock--1e47f";