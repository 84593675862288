import * as React from 'react';

import { BackToTop } from '../back-to-top/back-to-top.component';
import { CookieConsent } from '../cookie-consent/cookie-consent.component';
import { Footer } from '../footer/footer.component';
import * as styles from './layout.module.css';

const Layout: React.FC = ({ children }) => {
    return (
        <React.Fragment>
            <CookieConsent />
            <BackToTop />
            <main>
                <div className={styles.main}>
                    {children}
                </div>
            </main>
            <footer>
                <Footer />
            </footer>
        </React.Fragment>
    );
};
export default Layout;